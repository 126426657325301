import { FC } from "react";
import Button from "@/components/Button";
import LogoGoogle from "@/assets/imgs/svg/logo-google.svg";

interface Props {
  label?: string;
  onClick: () => void;
}

const GoogleSignInButton: FC<Props> = ({
  onClick,
  label = "Googleでログイン",
}): JSX.Element => {
  return (
    <Button
      leftIcon={<LogoGoogle width={16} height={16} />}
      onClick={onClick}
      type="button"
      color="outlined"
    >
      {label}
    </Button>
  );
};

export default GoogleSignInButton;

import { FC, PropsWithChildren, ReactNode } from "react";
import SignInTemplate from "@/components/SignInTemplate";
import Dialog from "@/components/Dialog";

interface Props {
  isOpen: boolean;
  close: VoidFunction;
  onDidLogin?: VoidFunction;
  message?: ReactNode;
  addCurrentPathToSignUpPath?: boolean;
}

const DialogSignIn: FC<PropsWithChildren<Props>> = ({
  isOpen,
  close,
  onDidLogin,
  message,
  addCurrentPathToSignUpPath = true,
}): JSX.Element => {
  return (
    <Dialog isOpen={isOpen} onClose={close} title="ログイン" hiddenCloseBtn>
      <SignInTemplate
        onDidLogin={onDidLogin}
        hasSignUpLink
        addCurrentPathToSignUpPath={addCurrentPathToSignUpPath}
        message={message}
      />
    </Dialog>
  );
};

export { DialogSignIn };

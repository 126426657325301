import { useRouter } from "next/router";
import { useCallback } from "react";

interface UseFromPathReturn {
  redirect: (path?: string) => void;
  generateLink: (path: string) => string;
  generateLinkWithCurrentPath: (path: string) => string;
  generateLinkByPathAndFrom: (path: string, from: string) => string;
  push: (path: string) => void;
  hasFromParameter: boolean;
}

const useFromPath = (): UseFromPathReturn => {
  const router = useRouter();
  const { from } = router.query;

  const redirect = useCallback(
    (path = "/") => {
      router.push((from as string) || path);
    },
    [from, router],
  );

  const generateLink = useCallback(
    (path: string) => (from ? `${path}?from=${from}` : path),
    [from],
  );

  const generateLinkWithCurrentPath = useCallback(
    (path: string) => {
      const currentPath = router.asPath;

      return `${path}?from=${currentPath}`;
    },
    [router],
  );

  const generateLinkByPathAndFrom = useCallback(
    (path, from) => `${path}?from=${from}`,
    [],
  );

  const push = useCallback(
    (path: string) => {
      router.push(generateLink(path));
    },
    [router, generateLink],
  );

  return {
    redirect,
    generateLink,
    generateLinkWithCurrentPath,
    generateLinkByPathAndFrom,
    push,
    hasFromParameter: !!router.query.from,
  };
};

export default useFromPath;

import useDisclosure from "@/hooks/useDisclosure";
import { RootState } from "@/stores/rootReducer";
import { ReactNode, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import { DialogSignIn } from "../components/DialogSignIn";

interface UseSignInRequiredActionArgs {
  action: () => void;
  message?: ReactNode;
  addCurrentPathToSignUpPath?: boolean;
}

interface UseSignInRequiredActionReturn {
  executeAction: () => void;
  SignInModalComponent: JSX.Element;
}

const useSignInRequiredAction = ({
  action,
  message,
  addCurrentPathToSignUpPath = true,
}: UseSignInRequiredActionArgs): UseSignInRequiredActionReturn => {
  const { isAuthenticated } = useSelector((state: RootState) => state.auth);
  const { isOpen, closeHandler, openHandler } = useDisclosure();

  const executeAction = useCallback(() => {
    if (isAuthenticated) {
      action();

      return;
    }

    openHandler();
  }, [action, isAuthenticated, openHandler]);

  const actionWithCloseModal = useCallback(() => {
    action();
    closeHandler();
  }, [action, closeHandler]);

  const SignInModalComponent = useMemo<JSX.Element>(() => {
    return (
      <DialogSignIn
        isOpen={isOpen}
        close={closeHandler}
        message={message}
        onDidLogin={actionWithCloseModal}
        addCurrentPathToSignUpPath={addCurrentPathToSignUpPath}
      />
    );
  }, [
    isOpen,
    actionWithCloseModal,
    closeHandler,
    message,
    addCurrentPathToSignUpPath,
  ]);

  return {
    executeAction,
    SignInModalComponent,
  };
};

export { useSignInRequiredAction };
